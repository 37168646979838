class CategoryDescription {
    constructor(selector) {
        this.selector = selector;
        this.button_expand = this.selector.querySelector('[data-button-expand]');
        this.button_collapse = this.selector.querySelector('[data-button-collapse]');
        this.content = this.selector.querySelector('[data-content]');

        if (this.button_collapse !== null) {
            this.button_collapse.style.opacity = 0;
            this.button_collapse.addEventListener('click', e => {
                e.preventDefault();

                if (this.selector.classList.contains('shown')) {
                    this.selector.classList.remove('shown');
                    this.button_collapse.innerText = '(Rozwiń)';
                    this.button_collapse.setAttribute('title', 'Rozwiń');
                    this.button_collapse.style.opacity = 1;
                } else {
                    this.selector.classList.add('shown');
                    this.button_collapse.innerText = '(Zwiń)';
                    this.button_collapse.setAttribute('title', 'Zwiń');
                    this.button_collapse.style.opacity = 0;
                }
            });
        }
        if (this.button_expand !== null) {
            this.button_expand.addEventListener('click', e => {
                e.preventDefault();

                if (this.selector.classList.contains('shown')) {
                    this.selector.classList.remove('shown');
                    this.button_collapse.innerText = '(Rozwiń)';
                    this.button_collapse.setAttribute('title', 'Rozwiń');
                    this.button_collapse.style.opacity = 1;
                } else {
                    this.selector.classList.add('shown');
                    this.button_collapse.innerText = '(Zwiń)';
                    setTimeout(() => {
                        this.button_collapse.setAttribute('title', 'Zwiń');
                        this.button_collapse.style.opacity = 0;
                    }, 300);
                }
            });
        }
    }
}
export default CategoryDescription;