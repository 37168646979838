class CustomTabs {
    constructor(selector) {
        this.selector = selector;
        this.parent = this.selector.parentNode;
        this.list = this.selector.querySelector('[data-element="list"]');
        this.content = this.selector.querySelector('[data-element="content"]');

        this.last_scrolling_height = 0;
        this.current_scrolling_height = 0;
        this.max_range = 0;
        this.box_width = 0;

        if (this.list !== null) {
            this.box_width = this.list.clientWidth;
            if (this.content !== null) {
                let tabs = this.list.querySelectorAll('a');
                if (tabs !== null) {
                    tabs.forEach(tab => {
                       tab.addEventListener('click', e => {
                            e.preventDefault();

                            let target = tab.getAttribute('data-target');
                            let section = document.getElementById(target);
                            let section_position = window.pageYOffset +  section.getBoundingClientRect().top;
                           window.scrollTo({
                               top: section_position - 75,
                               behavior: 'smooth'
                           });
                       });
                    });
                }
                window.addEventListener('scroll', () => {
                    this.current_scrolling_height =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                    console.log(parseInt(this.current_scrolling_height) + parseInt(this.list.offsetHeight) + parseInt(33), parseInt(this.max_range));
                    this.max_range = window.pageYOffset + this.parent.getBoundingClientRect().top + this.parent.offsetHeight;

                    if (this.last_scrolling_height > this.current_scrolling_height) {
                        if ((parseInt(this.current_scrolling_height) + parseInt(this.list.offsetHeight) + parseInt(33) <= parseInt(this.max_range))) {
                            this.list.classList.remove('absolute');
                            this.list.classList.add('sticky')
                        }
                        if (this.current_scrolling_height < (window.pageYOffset + this.parent.getBoundingClientRect().top - 45)) {
                            this.list.removeAttribute('style');
                            this.list.classList.remove('sticky');
                        }
                    } else if (this.current_scrolling_height > this.last_scrolling_height) {
                        if ((parseInt(this.current_scrolling_height) + parseInt(this.list.offsetHeight) + parseInt(33) >= parseInt(this.max_range))) {
                            this.list.classList.remove('sticky');
                            this.list.classList.add('absolute');
                        } else if (this.current_scrolling_height > (window.pageYOffset + this.parent.getBoundingClientRect().top - 45)) {
                            this.list.setAttribute('style', 'width: ' + this.box_width + 'px !important;');
                            this.list.classList.add('sticky');
                        }
                    }
                    this.last_scrolling_height = this.current_scrolling_height;
                });
            }
        }
    }
}
export default CustomTabs;