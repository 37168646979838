import Glide from "@glidejs/glide";

class DynamicTabs {
    constructor(selector) {
        this.selector = selector;
        this.buttons = this.selector.querySelector('[data-buttons]');
        this.promotion_products_carousel = this.selector.querySelector('[data-carousel="promotion-products"]');
        this.featured_products_carousel = this.selector.querySelector('[data-carousel="featured-products"]');

        this.ppc = null;
        this.fpc = null;

        if (this.promotion_products_carousel !== null) {
            let prev_button = this.promotion_products_carousel.querySelector('[data-element="button-prev"]');
            let next_button = this.promotion_products_carousel.querySelector('[data-element="button-next"]');

            this.ppc = new Glide(this.promotion_products_carousel, {
                type: 'carousel',
                perView: 4,
                autoplay: 3000,
                animationDuration: 500,
                gap: 30,
                peek: 30,
                bound: true,
                animationTimingFunc: 'linear',
                breakpoints: {
                    1499: {
                        perView: 3
                    },
                    991: {
                        perView: 2
                    },
                    640: {
                        perView: 1
                    }
                }
            }).mount();

            prev_button.addEventListener('click', (e) => {
                e.preventDefault();

                this.ppc.go('<');
            });
            next_button.addEventListener('click', (e) => {
                e.preventDefault();

                this.ppc.go('>');
            });
        }

        if (this.featured_products_carousel !== null) {
            let prev_button = this.featured_products_carousel.querySelector('[data-element="button-prev"]');
            let next_button = this.featured_products_carousel.querySelector('[data-element="button-next"]');

            this.fpc = new Glide(this.featured_products_carousel, {
                type: 'carousel',
                perView: 4,
                autoplay: 3000,
                animationDuration: 500,
                gap: 30,
                peek: 30,
                bound: true,
                animationTimingFunc: 'linear',
                breakpoints: {
                    1499: {
                        perView: 3
                    },
                    991: {
                        perView: 2
                    },
                    640: {
                        perView: 1
                    }
                }
            }).mount();

            prev_button.addEventListener('click', (e) => {
                e.preventDefault();

                this.fpc.go('<');
            });
            next_button.addEventListener('click', (e) => {
                e.preventDefault();

                this.fpc.go('>');
            });
        }

        if (this.buttons !== null) {
            let clickers = this.buttons.querySelectorAll('[data-clicker]');
            if (clickers !== null) {
                clickers.forEach(c => {
                    c.addEventListener('click', e => {
                        e.preventDefault();

                        let target = c.getAttribute('data-clicker');

                        this._clearTabs();
                        this._clearClickers();

                        c.classList.add('active');
                        this.selector.querySelector('[data-target="' + target + '"]').style.display = 'block';

                        if (target === 'polecane') {
                            this.fpc.update();
                        } else {
                            this.ppc.update();
                        }
                    });
                });
            }
        }
    }
    _clearTabs() {
        let targets = this.selector.querySelectorAll('[data-target]');
        targets.forEach(target => {
           target.style.display = 'none';
        });
    }
    _clearClickers() {
        let clickers = this.buttons.querySelectorAll('[data-clicker]');
        clickers.forEach(c => {
             c.classList.remove('active');
        });
    }
}
export default DynamicTabs;