class FilterBox {
    constructor(selector) {
        this.selector = selector;
        this.content = this.selector.querySelector('[data-element="content"]');
        this.button = this.selector.querySelector('[data-element="button"]');

        if (this.button !== null) {
            this._clickButton = this._clickButton.bind(this);
            this.button.addEventListener('click', this._clickButton);
        }
        if (window.innerWidth <= 768) {
            this.content.classList.remove('open');
            this.content.style.display = 'none';
            this.button.innerText = '(Rozwiń)';
        }
    }
    _clickButton(e) {
        e.preventDefault();

        if (!this.content.classList.contains('open')) {
            this.content.classList.add('open');
            this.content.style.display = 'block';
            this.button.innerText = '(Zwiń)';
        } else {
            this.content.classList.remove('open');
            this.content.style.display = 'none';
            this.button.innerText = '(Rozwiń)';
        }
    }
}
export default FilterBox;