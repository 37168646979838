class FiltersAction {
    constructor(selector) {
        this.selector = selector;
        this.filters = this.selector.querySelector('[data-content]');

        if (this.filters !== null) {
            let clickers = this.selector.querySelectorAll('[data-clicker]');
            if (clickers !== null) {
                clickers.forEach(clicker => {
                    clicker.addEventListener('clicker', e => {
                        e.preventDefault();

                        if (this.filters.classList.contains('shown')) {
                            this.filters.classList.remove('shown');
                        } else {
                            this.filters.classList.add('shown');
                        }
                    });
                });
            }
        }
    }
}
export default FiltersAction;