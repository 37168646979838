class PriceTypes {
    constructor(selector) {
        this.selector = selector;
        this.content = this.selector.querySelector('[data-content]');

        let options = this.selector.querySelectorAll('a');
        if (options !== null) {
            options.forEach(item => {
                item.addEventListener('click', e => {
                    e.preventDefault();

                    let type = item.getAttribute('data-type');
                    if (type === 'brutto') {
                        this.content.querySelector('[data-target="netto"]').style.display = 'none';
                        this.content.querySelector('[data-target="brutto"]').style.display = 'block';
                    } else {
                        this.content.querySelector('[data-target="netto"]').style.display = 'block';
                        this.content.querySelector('[data-target="brutto"]').style.display = 'none';
                    }
                    this._clearAllOptions();
                    item.classList.add('active');
                });
            });
        }
    }
    _clearAllOptions() {
        let options = this.selector.querySelectorAll('a');
        if (options !== null) {
            options.forEach(item => {
                item.classList.remove('active');
            });
        }
    }
}
export default PriceTypes;