import { Form } from '@netivo/base-scripts';
import { initMap } from '@netivo/base-scripts';
import Glide from "@glidejs/glide/entry/entry-complete";
import SimpleLightbox from "simple-lightbox/dist/simpleLightbox.min";
import MobileSearch from "./mobileSearch";
import MobileNavigation from "./mobileNavigation";
import Question from "./question";
import PriceTypes from "./priceTypes";
import QuantityInput from "./quantityInput";
import CustomTabs from "./customTabs";
import MegaMenu from "./megaMenu";
import DynamicTabs from "./dynamicTabs";
import FiltersAction from "./filtersAction";
import FilterBox from "./filterBox";
import CategoryTabs from "./categoryTabs";
import FilterAttributes from "./filterAttributes";
import HiddenFilterOptions from "./hiddenFilterOptions";
import MenuPromotion from "./menuPromotion";
import StickySidebar from "./stickySidebar";
import CategoryDescription from "./categoryDescription";

let images = document.querySelectorAll('img');
if (images !== null) {
    images.forEach(image => {
        if (!image.classList.contains('logo')) {
            image.setAttribute('loading', 'lazy');
        }
    });
}

window.initMap = initMap;

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

window.addEventListener('DOMContentLoaded', () => {

    // google.maps.event.addEventListener(window, 'load', initMap);

    let rc = null;
    let realizations_carousel = document.querySelector('.js-realizations-carousel');
    if (realizations_carousel !== null) {
        rc = new Glide(realizations_carousel, {
            type: 'slider',
            perView: 4,
            autoplay: 0,
            bound: true,
            animationDuration: 500,
            gap: 0,
            animationTimingFunc: 'linear',
            breakpoints: {
                1024: {
                    perView: 3
                },
                640: {
                    perView: 1
                }
            }
        }).mount();
    }

    let pc = null;
    let producents_carousel = document.querySelector('.js-producents-carousel');
    if (producents_carousel !== null) {
        let prev_button = producents_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = producents_carousel.parentNode.querySelector('[data-element="button-next"]');

        pc = new Glide(producents_carousel, {
            type: 'carousel',
            perView: 5,
            autoplay: 3000,
            animationDuration: 500,
            gap: 50,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                1024: {
                    perView: 3
                },
                640: {
                    perView: 2
                },
                500: {
                    perView: 1
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            pc.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            pc.go('>');
        });
    }

    let hc = null;
    let home_carousel = document.querySelector('.js-home-carousel');
    if (home_carousel !== null) {
        let prev_button = home_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = home_carousel.parentNode.querySelector('[data-element="button-next"]');

        hc = new Glide(home_carousel, {
            type: 'carousel',
            gap: 0,
            perView: 1,
            autoplay: 3500,
            animationDuration: 1,
            animationTimingFunc: 'ease-in-out',
            breakpoints: {
                400: {
                    autoplay: 4500,
                },
                600: {
                    autoplay: 3500,
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            hc.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            hc.go('>');
        });

        // if (window.innerWidth >= 1920) {
        //     home_carousel.parentNode.style.height = '650px';
        //     let slides = home_carousel.querySelectorAll('.home-carousel__slide');
        //     slides.forEach(slide => {
        //         slide.style.height = '650px';
        //     });
        // } else {
            // let ratio = 650/1920;
            // let new_height = window.innerWidth*ratio;
            //
            // console.log(ratio, window.innerWidth, (window.innerWidth*ratio));
            //
            // home_carousel.parentNode.style.height = new_height + 'px';
            // let slides = home_carousel.querySelectorAll('.home-carousel__slide');
            // slides.forEach(slide => {
            //     slide.style.height = new_height + 'px';
            // });
        // }
    }

    let refc = null;
    let references_carousel = document.querySelector('.js-references-carousel');
    if (references_carousel !== null) {
        let prev_button = references_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = references_carousel.parentNode.querySelector('[data-element="button-next"]');

        refc = new Glide(references_carousel, {
            type: 'carousel',
            perView: 5,
            autoplay: 3000,
            animationDuration: 500,
            gap: 50,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                1024: {
                    perView: 3
                },
                767: {
                    perView: 2
                },
                640: {
                    perView: 1
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            refc.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            refc.go('>');
        });
    }

    let prods = null;
    let product_carousel = document.querySelector('.js-product-slider');
    if (product_carousel !== null) {
        prods = new Glide(product_carousel, {
            type: 'carousel',
            perView: 4,
            autoplay: 3000,
            animationDuration: 500,
            gap: 30,
            peek: 30,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                1199: {
                    perView: 3
                },
                991: {
                    perView: 2
                },
                640: {
                    perView: 1
                }
            }
        }).mount();
    }

    let prodrels = null;
    let product_related_carousel = document.querySelector('.js-product-related-slider');
    if (product_related_carousel !== null) {
        prodrels = new Glide(product_related_carousel, {
            type: 'carousel',
            perView: 4,
            autoplay: 3000,
            animationDuration: 500,
            gap: 30,
            peek: 30,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                1199: {
                    perView: 3
                },
                991: {
                    perView: 2
                },
                640: {
                    perView: 1,
                    focusAt: 'center'
                }
            }
        }).mount();
    }

    let lightbox = new SimpleLightbox({elements: '.js-gallery a:not(.carousel-button)' });
    let lightbox_product_gallery = new SimpleLightbox({elements: '.js-product-gallery a' });

    let mobile_search_bar = document.querySelector('.js-search-bar');
    if (mobile_search_bar !== null) {
        new MobileSearch(mobile_search_bar);
    }

    let mobile_navigation = document.querySelector('.js-mobile-navigation');
    if (mobile_navigation !== null) {
        new MobileNavigation(mobile_navigation);
    }

    let questions = document.querySelectorAll('.js-question');
    if (questions !== null) {
        questions.forEach(q => {
            new Question(q);
        });
    }

    let price_types = document.querySelectorAll('.js-price-types');
    if (price_types !== null) {
        price_types.forEach(pt => {
            new PriceTypes(pt);
        });
    }

    let quantity_inputs = document.querySelectorAll('.js-quantity');
    if (quantity_inputs !== null) {
        quantity_inputs.forEach(qi => {
            new QuantityInput(qi);
        });
    }

    let custom_tabs = document.querySelector('.js-custom-tabs');
    if (custom_tabs !== null) {
        new CustomTabs(custom_tabs);
    }

    let mega_menu = document.querySelector('.js-navigation');
    if (mega_menu !== null) {
        new MegaMenu(mega_menu);
    }

    let dynamic_tabs = document.querySelector('.js-dynamic-tabs');
    if (dynamic_tabs !== null) {
        new DynamicTabs(dynamic_tabs);
    }

    let filters = document.querySelector('.js-shop-filters');
    if (filters !== null) {
        new FiltersAction(filters);
    }

    let filter_boxes = document.querySelectorAll('.js-filter-box');
    if (filter_boxes !== null) {
        filter_boxes.forEach(fb => {
            new FilterBox(fb);
        });
    }

    let category_tabs = document.querySelectorAll('.js-category-tabs');
    if (category_tabs !== null) {
        category_tabs.forEach(ct => {
           new CategoryTabs(ct);
        });
    }

    let filters_attributes = document.querySelectorAll('.js-attribute-filter');
    if (filters_attributes !== null) {
        filters_attributes.forEach(fsa => {
            new FilterAttributes(fsa);
        });
    }

    let hidden_filter_options = document.querySelectorAll('.js-hidden-filter-options');
    if (hidden_filter_options !== null) {
        hidden_filter_options.forEach(hfo => {
             new HiddenFilterOptions(hfo);
        });
    }

    let promotion_boxes = document.querySelectorAll('.js-promotion-box');
    if (promotion_boxes !== null) {
        promotion_boxes.forEach(promo_box => {
            new MenuPromotion(promo_box);
        });
    }

    let sticky_sidebar = document.querySelector('.js-sticky-sidebar');
    if (sticky_sidebar !== null) {
        new StickySidebar(sticky_sidebar);
    }

    let category_description_collapse = document.querySelector('.js-category-description-collapse');
    if (category_description_collapse !== null) {
        new CategoryDescription(category_description_collapse);
    }

    let tpc = null;
    let thumbnails_product_carousel = document.querySelector('.js-thumbnails-product-carousel');
    if (thumbnails_product_carousel !== null) {
        tpc = new Glide(thumbnails_product_carousel, {
            type: 'slider',
            perView: 3,
            animationDuration: 500,
            gap: 25,
            clone: false,
            startAt: 0,
            animationTimingFunc: 'linear',
            breakpoints: {
                1199: {
                    perView: 3
                },
                991: {
                    perView: 2
                },
                640: {
                    perView: 1
                }
            }
        }).mount();
    }

    let spc = null;
    let single_product_carousel = document.querySelector('.js-single-product-carousel');
    if (single_product_carousel !== null) {
        spc = new Glide(single_product_carousel, {
            type: 'carousel',
            gap: 0,
            perView: 1,
            animationDuration: 1,
            animationTimingFunc: 'ease-in-out',
        }).mount();
    }

    if (spc !== null && tpc !== null) {
        Array.from(document.querySelectorAll('.slide-nav')).forEach(el => {
            el.addEventListener('click', (e) => {
                console.log(e.target);
                spc.go('='+(e.target.dataset.index));
                tpc.go('='+(e.target.dataset.index));
            });
        });

        spc.on('swipe.end', function() {
            tpc.go('='+(spc.index));
        });

        spc.on('move.after', function() {
            tpc.go('='+(spc.index));
        });
    }

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        console.log(contact_form);
        let recaptcha_key = contact_form.getAttribute('data-recaptcha');
        new Form(contact_form, {
            action: '/wp-json/pg/v1/contact_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_last_name: '',
                        email: '',
                        message: '',
                        recaptcha: '',
                    };

                    let form_first_last_name = el.querySelector('[name="first_last_name"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_first_last_name !== null) data.first_last_name = form_first_last_name.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_message !== null) data.message = form_message.value;

                    grecaptcha.ready(function() {
                        grecaptcha.execute(recaptcha_key, {action: 'contact'}).then(function (token) {
                            data.recaptcha = token;
                            resolve(data);
                        });
                    });
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="first_last_name"]').value = '';
                        el.querySelector('[name="email"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                        setTimeout(() => {
                            message.classList.remove('shown');
                            status.classList.remove('shown');
                            status.style.display = 'none';
                        }, 5000);
                    }, 500);
                }
            },
            error: (el, response) => {
                console.log(response);
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');

                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');

                setTimeout( () => {
                    message.classList.add('shown');
                    setTimeout(() => {
                        message.classList.remove('shown');
                        status.classList.remove('shown');
                        status.style.display = 'none';
                    }, 5000);
                }, 500);
            }
        });
    }

    let leasing_form = document.querySelector('.js-leasing-form');
    if (leasing_form !== null) {
        let recaptcha_key = leasing_form.getAttribute('data-recaptcha');
        new Form(leasing_form, {
            action: '/wp-json/pg/v1/leasing_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_last_name: '',
                        email: '',
                        message: '',
                        recaptcha: '',
                    };

                    let form_first_last_name = el.querySelector('[name="first_last_name"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_first_last_name !== null) data.first_last_name = form_first_last_name.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_message !== null) data.message = form_message.value;

                    grecaptcha.ready(function() {
                        grecaptcha.execute(recaptcha_key, {action: 'leasing'}).then(function (token) {
                            data.recaptcha = token;
                            resolve(data);
                        });
                    });
                });
            },
            beforeSubmit: (el, hashed) => {
                return true;
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="first_last_name"]').value = '';
                        el.querySelector('[name="email"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                        setTimeout(() => {
                            message.classList.remove('shown');
                            status.classList.remove('shown');
                            status.style.display = 'none';
                        }, 5000);
                    }, 500);
                }
            },
            error: (el, response) => {
                console.log(response);
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');

                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');

                setTimeout( () => {
                    message.classList.add('shown');
                    setTimeout(() => {
                        message.classList.remove('shown');
                        status.classList.remove('shown');
                        status.style.display = 'none';
                    }, 5000);
                }, 500);
            }
        });
    }
});