class MobileNavigation {
    constructor(selector) {
        this.selector = selector;
        this.clickers = this.selector.querySelectorAll('[data-clicker]');

        console.log(this.clickers);

        this.clickers.forEach(span => {
            span.addEventListener('click', e => {

                console.log('clicked span ' + span.classList);
                e.preventDefault();

                if (!span.parentNode.classList.contains('active')) {
                    span.parentNode.classList.add('active');
                    if (span.parentNode.parentNode.querySelector('ul') !== null) {
                        span.parentNode.parentNode.querySelector('ul').classList.add('shown');
                    }
                    span.innerText = '-';
                } else {
                    span.parentNode.classList.remove('active');
                    if (span.parentNode.parentNode.querySelector('ul') !== null) {
                        span.parentNode.parentNode.querySelector('ul').classList.remove('shown');
                    }
                    span.innerText = '+';
                }
            });
        });
    }
}
export default MobileNavigation;