class MobileSearch {
    constructor(selector) {
        this.selector = selector;
        this.search = this.selector.closest('.header__top--mobile');

        this.selector.addEventListener('click', e => {
            e.preventDefault();

            if (this.search.classList.contains('shown')) {
                this.search.style.display = 'none';
                this.search.classList.remove('shown');
            } else {
                this.search.style.display = 'block';
                this.search.classList.add('shown');
            }
        });
    }
}
export default MobileSearch;