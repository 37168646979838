class Question {
    constructor(selector) {
        this.selector = selector;
        this.clicker = this.selector.querySelector('[data-clicker]');

        if (this.clicker !== null) {
            this.clicker.addEventListener('click', e => {
                e.preventDefault();

                this.selector.querySelector('.js-phone-number').style.display = 'block';
            });
        }
    }
}
export default Question;